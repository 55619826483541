import React from "react"
import Box from "@material-ui/core/Box"
import People from "@material-ui/icons/People"
import PhoneInTalk from "@material-ui/icons/PhoneInTalk"
import Directions from "@material-ui/icons/Directions"
import TrackChanges from "@material-ui/icons/TrackChanges"
import FiberNew from "@material-ui/icons/FiberNew"
import DataUsage from "@material-ui/icons/DataUsage"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  believeIcons: {
    color: "#29ABE2",
    fontSize: 48,
    alignSelf: "center",
  },
  greenIcons: {
    color: "#22B573",
    fontSize: 48,
    alignSelf: "center",
  },
  yellowIcons: {
    color: "#EAAD38",
    fontSize: 48,
    alignSelf: "center",
  },
  redIcons: {
    color: "#DF235E",
    fontSize: 48,
    alignSelf: "center",
  },
})

export default function Believe() {
  const classes = useStyles()
  return (
    <div>
      <Box
        display="flex"
        fontSize="h2.fontSize"
        fontWeight={500}
        letterSpacing={1.5}
        lineHeight={1.5}
        mx="auto"
        pt={10}
        pb={1}
        width={8 / 10}
      >
        We Believe
      </Box>
      <Box
        width={8 / 10}
        my={{ xs: 1, sm: 1, md: 5 }}
        mx="auto"
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          width={{ xs: 1, sm: 1, md: 3 / 10 }}
          order={1}
          alignSelf="flex-start"
          textAlign="center"
          py={4}
        >
          <Box my={2}>
            <PhoneInTalk className={classes.believeIcons} />
          </Box>
          <Box fontSize="h5.fontSize" letterSpacing={1.5} lineHeight={1.5}>
            Nothing happens without a conversation.
          </Box>
        </Box>
        <Box
          width={{ xs: 1, sm: 1, md: 3 / 10 }}
          order={2}
          alignSelf="flex-start"
          textAlign="center"
          py={4}
        >
          <Box my={2}>
            <Directions className={classes.redIcons} />
          </Box>
          <Box fontSize="h5.fontSize" letterSpacing={1.5} lineHeight={1.5}>
            Great dashboards guide today and help predict the future.
          </Box>
        </Box>
        <Box
          width={{ xs: 1, sm: 1, md: 3 / 10 }}
          order={3}
          alignSelf="flex-start"
          textAlign="center"
          py={4}
        >
          <Box my={2}>
            <People aria-label="people" className={classes.believeIcons} />
          </Box>
          <Box fontSize="h5.fontSize" letterSpacing={1.5} lineHeight={1.5}>
            You need a partner, not another vendor.
          </Box>
        </Box>
      </Box>
      <Box
        width={8 / 10}
        my={{ xs: 1, sm: 1, md: 5 }}
        mx="auto"
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          width={{ xs: 1, sm: 1, md: 3 / 10 }}
          order={1}
          alignSelf="flex-start"
          textAlign="center"
          py={4}
        >
          <Box my={2}>
            <TrackChanges className={classes.greenIcons} />
          </Box>
          <Box fontSize="h5.fontSize" letterSpacing={1.5} lineHeight={1.5}>
            Data needs to be actionable.
          </Box>
        </Box>
        <Box
          width={{ xs: 1, sm: 1, md: 3 / 10 }}
          order={2}
          alignSelf="flex-start"
          textAlign="center"
          py={4}
        >
          <Box my={2}>
            <FiberNew className={classes.yellowIcons} />
          </Box>
          <Box fontSize="h5.fontSize" letterSpacing={1.5} lineHeight={1.5}>
            Dashboards must provide recommendations.
          </Box>
        </Box>
        <Box
          width={{ xs: 1, sm: 1, md: 3 / 10 }}
          order={3}
          alignSelf="flex-start"
          textAlign="center"
          py={4}
        >
          <Box my={2}>
            <DataUsage aria-label="people" className={classes.greenIcons} />
          </Box>
          <Box fontSize="h5.fontSize" letterSpacing={1.5} lineHeight={1.5}>
            Your people and your data are your most valuable assets.
          </Box>
        </Box>
      </Box>
    </div>
  )
}
