import React from "react"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Hidden from "@material-ui/core/Hidden"
import AWS from "../images/ASW.svg"
import Microsoft from "../images/microsoft.svg"
import Salesforce from "../images/salesforce.svg"
import SAP from "../images/sap.svg"
import ServiceNow from "../images/servicenow.svg"
import Tableau from "../images/tableau2.svg"
import Workday from "../images/workday-01_0.svg"
import Google from "../images/google.svg"

const logos = () => {
  return (
    <Box
      mt={{ xs: 1, sm: 8, md: 6, lg: 18 }}
      py={{ xs: 8, sm: 6, md: 6, lg: 10 }}
      width={4 / 5}
      maxWidth="1450px"
      mx="auto"
      overflow="hidden"
    >
      <Grid container direction="row" justify="space-between" spacing={7}>
        <Grid item xs={10} sm={5} md={4} lg={3}>
          <Google />
        </Grid>
        <Grid item xs={10} sm={5} md={4} lg={3}>
          <AWS />
        </Grid>
        <Grid item xs={10} sm={5} md={4} lg={3}>
          <SAP />
        </Grid>
        <Grid item xs={10} sm={5} md={4} lg={3}>
          <Microsoft />
        </Grid>
        <Hidden only={["xs", "sm"]}>
          <Grid item xs={5} sm={5} md={4} lg={3}>
            <Salesforce />
          </Grid>
        </Hidden>
        <Hidden only={["xs", "sm"]}>
          <Grid item xs={5} sm={5} md={4} lg={3}>
            <ServiceNow />
          </Grid>
        </Hidden>
        <Hidden only={["xs", "sm", "md"]}>
          <Grid item xs={5} sm={6} md={4} lg={3}>
            <Tableau />
          </Grid>
        </Hidden>
        <Hidden only={["xs", "sm", "md"]}>
          <Grid item xs={5} sm={6} md={4} lg={3}>
            <Workday />
          </Grid>
        </Hidden>
      </Grid>
    </Box>
  )
}

export default logos
