import React from "react"
import Box from "@material-ui/core/Box"
import { Link } from "gatsby"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  root: {
    width: "100%",
    backgroundColor: "#f5f5f5",
  },
  ctaButton: {
    fontSize: "1.5rem",
    fontWeight: "500",
    letterSpacing: "0.15rem",
    margin: "1rem",
  },
})

export default function CTA() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Box
        width={8 / 10}
        py={10}
        mx="auto"
        display="flex"
        flexDirection="column"
      >
        <Box
          fontSize="h2.fontSize"
          fontWeight={500}
          letterSpacing={1.5}
          lineHeight={1.5}
          m={1}
          textAlign="center"
        >
          Schedule a Dashboard Consultation
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          flexWrap="wrap"
        >
          <Box textAlign="center">
            <Button
              className={classes.ctaButton}
              component={Link}
              to="/data"
              variant="contained"
              size="large"
              color="primary"
            >
              Schedule Now
            </Button>
          </Box>
          <Box textAlign="center">
            <Button
              className={classes.ctaButton}
              component={Link}
              to="/contact"
              variant="contained"
              size="large"
              color="primary"
            >
              Let's Talk First
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  )
}
