import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"

const Dashboards = () => {
  const data = useStaticQuery(graphql`
    fragment logoImage on File {
      childImageSharp {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    query {
      image1: file(relativePath: { eq: "traffic_dashboard.png" }) {
        ...logoImage
      }
      image2: file(relativePath: { eq: "iot_dashboard.png" }) {
        ...logoImage
      }
      image3: file(relativePath: { eq: "performance.png" }) {
        ...logoImage
      }
      image4: file(relativePath: { eq: "analytics_dashboard.png" }) {
        ...logoImage
      }
      image5: file(relativePath: { eq: "traffic_audience.png" }) {
        ...logoImage
      }
      image6: file(relativePath: { eq: "sales_dashboard.png" }) {
        ...logoImage
      }
    }
  `)
  return (
    <Box py={6} width={4 / 5} maxWidth="1450px" mx="auto">
      <Grid container direction="row" justify="space-between" spacing={4}>
        <Grid item xs={12}>
          <Box fontSize="h2.fontSize" letterSpacing={1.5} lineHeight={1.5}>
            Decision Ready Dashboards
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box fontSize="h4.fontSize" letterSpacing={1.5}>
            We partner with you to turn "raw data" into valuable insights.
          </Box>
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          <Img fluid={data.image1.childImageSharp.fluid} />
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          <Img fluid={data.image2.childImageSharp.fluid} />
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          <Img fluid={data.image3.childImageSharp.fluid} />
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          <Img fluid={data.image4.childImageSharp.fluid} />
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          <Img fluid={data.image5.childImageSharp.fluid} />
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          <Img fluid={data.image6.childImageSharp.fluid} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Dashboards
