import React from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  buttonHero: {
    fontSize: "1.5rem",
    letterSpacing: "0.15rem",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
}))

export default function Hero() {
  const classes = useStyles()
  return (
    <Box
      display="flex"
      flexDirection="column"
      my={{ xs: 0, sm: 8, md: 15 }}
      width={4 / 5}
      mx="auto"
    >
      <Box fontSize="h1.fontSize" lineHeight={1.5} letterSpacing={1.5} py={1}>
        Data + Insights = Better Companies
      </Box>
      <Box fontSize="h3.fontSize" lineHeight={1.5} letterSpacing={1.5} py={1}>
        We are a data consulting firm helping companies gain insights about
        today and predict tomorrow with custom dashboards.
      </Box>
      <Box py={3}>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          size="large"
          to="/contact"
          className={classes.buttonHero}
        >
          Tell Us About Your Project
        </Button>
      </Box>
    </Box>
  )
}
