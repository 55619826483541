import React from "react"
import Box from "@material-ui/core/Box"
import Particles from "react-particles-js"
import Hero from "./hero"
import Header from "../components/header"
import MobileNav from "../components/mobileNav"

const HeroBackground = () => {
  return (
    <>
      <Box component="span" display="block">
        <Particles
          width="100%"
          height="100%"
          params={{
            particles: {
              number: {
                value: 150,
                density: {
                  enable: true,
                  value_area: 868.0624057955,
                },
              },
              color: {
                value: "#29ABE2",
              },
              shape: {
                type: "circle",
                stroke: {
                  width: 0,
                  color: "#000000",
                },
                polygon: {
                  nb_sides: 5,
                },
                image: {
                  src: "img/github.svg",
                  width: 100,
                  height: 100,
                },
              },
              opacity: {
                value: 0.7,
                random: true,
                anim: {
                  enable: false,
                  speed: 1,
                  opacity_min: 0.1,
                  sync: false,
                },
              },
              size: {
                value: 3,
                random: true,
                anim: {
                  enable: false,
                  speed: 40,
                  size_min: 0.1,
                  sync: false,
                },
              },
              line_linked: {
                enable: true,
                distance: 150,
                color: "#22B573",
                opacity: 0.4,
                width: 1,
              },
              move: {
                enable: true,
                speed: 7,
                direction: "none",
                random: false,
                straight: false,
                out_mode: "out",
                bounce: false,
                attract: {
                  enable: false,
                  rotateX: 600,
                  rotateY: 1200,
                },
              },
            },
            interactivity: {
              detect_on: "canvas",
              events: {
                onhover: {
                  enable: true,
                  mode: "repulse",
                },
                onclick: {
                  enable: true,
                  mode: "push",
                },
                resize: false,
              },
              modes: {
                grab: {
                  distance: 400,
                  line_linked: {
                    opacity: 1,
                  },
                },
                bubble: {
                  distance: 400,
                  size: 40,
                  duration: 2,
                  opacity: 8,
                  speed: 3,
                },
                repulse: {
                  distance: 200,
                  duration: 0.4,
                },
                push: {
                  particles_nb: 4,
                },
                remove: {
                  particles_nb: 2,
                },
              },
            },
            retina_detect: true,
          }}
          style={{
            backgroundColor: "#FFFF",
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: "-1",
          }}
        />
        <Header />
        <MobileNav />
        <Hero />
      </Box>
    </>
  )
}

export default HeroBackground
