import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import CheckCircle from "@material-ui/icons/CheckCircleOutlined"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import Button from "@material-ui/core/Button"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "rgba(37, 37, 39, 0.97)",
    width: "100%",
  },
  listText: {
    color: "white",
    fontSize: "1.25rem",
    letterSpacing: "0.15rem",
  },
  icon: {
    color: "white",
    fontSize: "36",
  },
  buttonCta: {
    fontSize: "1.75rem",
    letterSpacing: "0.15rem",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))

export default function Solutions() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Box width={4 / 5} mx="auto" py={15}>
        <Box
          fontWeight={500}
          fontSize="h2.fontSize"
          color="#fff"
          letterSpacing={1.5}
          lineHeight={1.5}
        >
          Strategy. Data Engineering. Dashboard Design
        </Box>
        <Box>
          <Box
            fontSize="h4.fontSize"
            color="#FFF"
            letterSpacing={1.5}
            lineHeight={1.5}
          >
            The Dashboard Company builds beautiful, intuitive, decision-ready
            dashboards for forward thinking organizations and leaders. We are
            data people and help you discover insights and tell stories visually
            with your data. Stop wasting time and money pretending to make sound
            business decisions with bad data.
          </Box>
        </Box>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          my={4}
        >
          <Box order={1} width={{ sm: 1, md: 1 / 4, lg: 3 / 10 }} m={2}>
            <Box
              fontSize="h4.fontSize"
              color="#FFF"
              letterSpacing={1.5}
              lineHeight={1.5}
            >
              Strategy
            </Box>
            <Box
              fontSize="h6.fontSize"
              color="#FFF"
              letterSpacing={1.5}
              lineHeight={1.5}
            >
              We help you get the most out of your data. Our strategy services
              are collaborative, explorative, and dynamic.
            </Box>
            <List>
              <ListItem>
                <ListItemIcon className={classes.icon}>
                  <CheckCircle />
                </ListItemIcon>
                <ListItemText
                  className={classes.listText}
                  primary="Scorecard Development"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon className={classes.icon}>
                  <CheckCircle />
                </ListItemIcon>
                <ListItemText
                  className={classes.listText}
                  primary="Key Performance Measures (KPI)"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon className={classes.icon}>
                  <CheckCircle />
                </ListItemIcon>
                <ListItemText
                  className={classes.listText}
                  primary="Data Model Design"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon className={classes.icon}>
                  <CheckCircle />
                </ListItemIcon>
                <ListItemText
                  className={classes.listText}
                  primary="Data Discovery Missions"
                />
              </ListItem>
            </List>
          </Box>
          <Box order={2} width={{ sm: 1, md: 1 / 4, lg: 3 / 10 }} m={2}>
            <Box
              fontSize="h4.fontSize"
              color="#FFF"
              letterSpacing={1.5}
              lineHeight={1.5}
            >
              Data Engineering
            </Box>
            <Box
              fontSize="h6.fontSize"
              color="#FFF"
              letterSpacing={1.5}
              lineHeight={1.5}
            >
              We take your data from multiple sources and make it useful,
              available and formated to be used universally throughout your
              reporting and dashboards.
            </Box>
            <List>
              <ListItem>
                <ListItemIcon className={classes.icon}>
                  <CheckCircle />
                </ListItemIcon>
                <ListItemText
                  className={classes.listText}
                  primary="Data Wrangling"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon className={classes.icon}>
                  <CheckCircle />
                </ListItemIcon>
                <ListItemText
                  className={classes.listText}
                  primary="Statistical Analysis"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon className={classes.icon}>
                  <CheckCircle />
                </ListItemIcon>
                <ListItemText
                  className={classes.listText}
                  primary="Data Modeling"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon className={classes.icon}>
                  <CheckCircle />
                </ListItemIcon>
                <ListItemText
                  className={classes.listText}
                  primary="ETL and Data Transformation"
                />
              </ListItem>
            </List>
          </Box>
          <Box order={2} width={{ sm: 1, md: 1 / 4, lg: 3 / 10 }} m={2}>
            <Box
              fontSize="h4.fontSize"
              color="#FFF"
              letterSpacing={1.5}
              lineHeight={1.5}
            >
              Dashboard Building
            </Box>
            <Box
              fontSize="h6.fontSize"
              color="#FFF"
              letterSpacing={1.5}
              lineHeight={1.5}
            >
              We build beautiful dashboards to help you visualize what is
              happening in your business and where to go next.
            </Box>
            <List>
              <ListItem>
                <ListItemIcon className={classes.icon}>
                  <CheckCircle />
                </ListItemIcon>
                <ListItemText
                  className={classes.listText}
                  primary="Dashboard Development"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon className={classes.icon}>
                  <CheckCircle />
                </ListItemIcon>
                <ListItemText
                  className={classes.listText}
                  primary="Data Story Infographics"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon className={classes.icon}>
                  <CheckCircle />
                </ListItemIcon>
                <ListItemText
                  className={classes.listText}
                  primary="Management Dashboard Design"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon className={classes.icon}>
                  <CheckCircle />
                </ListItemIcon>
                <ListItemText
                  className={classes.listText}
                  primary="Geospatial Mapping"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon className={classes.icon}>
                  <CheckCircle />
                </ListItemIcon>
                <ListItemText
                  className={classes.listText}
                  primary="Digital Signage"
                />
              </ListItem>
            </List>
          </Box>
        </Box>
        <Box textAlign="center" py={4}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={classes.buttonCta}
          >
            Schedule a 20 minute Idea Session
          </Button>
        </Box>
      </Box>
    </div>
  )
}
