import React from "react"
import SEO from "../components/seo"
import Believe from "../components/believe"
import CTA from "../components/cta"
import HeroBackground from "../components/heroBackground"
import Solutions from "../components/solutions"
import Layout from "../components/layout/layout"
import Dashboards from "../components/dashboards"
import Logos from "../components/logos"

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <Layout>
      <HeroBackground />
      <Logos />
      <Solutions />
      <Believe />
      <Dashboards />
      <CTA />
    </Layout>
  </>
)

export default IndexPage
